<template>
  <div id="app" class="font-main bg-grey-light-3">
    <Loader />
    <Toast />
    <HeaderWrapper />
    <Content />
    <component :is="modal" :visible="modalVisible" v-bind="modalProps" />
  </div>
</template>

<script>
import HeaderWrapper from "./components/layout/Headers";
import Content from "./components/layout/Content";
import Loader from "./components/layout/Loader";
import QuitSurveyModal from "./components/Modal/QuitSurveyModal";
import FinishTimeModal from './components/Modal/FinishTimeModal.vue';
import LeaveParticipantsModal from "./components/Modal/LeaveParticipantsModal";
import RemoveParticipantModal from "./components/Modal/RemoveParticipantModal";
import AddingParticipantsModal from "./components/Modal/AddingParticipantsModal";
import DataDeletionModal from "./components/Modal/DataDeletionModal";
import ConfirmDataDeleteModal from "./components/Modal/ConfirmDataDeleteModal";
import ConfirmSubscriptionUpdateModal from "./views/Companies/components/SubscriptionDetailsForm/ConfirmSubscriptionUpdateModal.vue";
import RequestDeleteModal from "./components/Modal/RequestDeleteModal";
import UsersPreview from "./views/AdminAssessments/components/UsersPreview";
import CreateGroup from "./views/AdminAssessments/components/CreateGroup";
import JobRoleModal from "./views/AdminAssessments/components/JobRoleModal";
import IndividualUserModal from "./views/AdminAssessments/components/IndividualUserModal";
import ManageUserModal from "./views/People/components/ManageUserModal";
import CreateTeamModal from "./views/AdminTeams/components/CreateTeamModal";
import DeleteTeamModal from "./views/AdminTeams/components/DeleteTeamModal";
import RemoveMemberModal from "./views/AdminTeams/components/RemoveMemberModal";
import CreateCompanyModal from "./views/Companies/components/CreateCompanyModal";
import EditAdminModal from "./views/Companies/components/EditAdminModal";
import AddSellersModal from "./views/Companies/components/AddSellersModal";
import AddInvitesModal from "./views/Companies/components/Licenses/AddInvitesModal";
import RemoveInvitesModal from "./views/Companies/components/Licenses/RemoveInvitesModal.vue";
import ToggleUnlimitedInvitesModal from "./views/Companies/components/Licenses/ToggleUnlimitedInvitesModal.vue";
import ExitAssessmentModal from "./views/AdminAssessments/components/ExitAssessmentModal";
import AssessmentDateModal from "./views/AdminAssessments/components/AssessmentDateModal.vue";
import DeactivateAccountModal from "./views/Companies/components/DeactivateAccountModal.vue";
import DeactivateJobRoleModal from "./views/Companies/components/DeactivateJobRoleModal.vue";
import EditJobRoleOrCompetencyModal from "./views/Companies/components/EditJobRoleOrCompetencyModal.vue";
import DeactivateCustomerUserModal from "./views/Companies/components/DeactivateCustomerUserModal.vue";
import RemoveAdminRightsModal from "./views/Companies/components/RemoveAdminRightsModal.vue";
import DeactivateCompanyModal from "./views/Companies/components/DeactivateCompanyModal.vue";
import DeleteProductModal from "./views/Products/components/DeleteProductModal.vue";
import DeleteDraftAssessmentModal from "./views/Products/components/DeleteDraftAssessmentModal.vue";
import DeleteCompetencyModal from "./views/Products/components/DeleteCompetencyModal.vue";
import CopyCompetenciesModal from "./views/Products/components/CopyCompetenciesModal.vue";
import CopyJobRolesModal from "./views/Products/components/CopyJobRolesModal.vue";
import DeleteJobRoleModal from "./views/Products/components/DeleteJobRoleModal.vue";
import DeleteSurveyModal from "./views/Products/components/DeleteSurveyModal.vue";
import CompleteSetupModal from "./views/AdminAssessments/components/CompleteSetupModal.vue";
import PublishProductModal from "./views/Products/components/PublishProductModal.vue";
import ManageAssessmentModal from "./views/Products/components/ManageAssessmentModal.vue";
import RetireAssessmentModal from "./views/Products/components/RetireAssessmentModal.vue";
import NewVersionSettingsModal from "./views/Products/components/NewVersionSettingsModal.vue";
import ScheduleProductLaunchModal from "./views/Products/components/ScheduleProductLaunchModal.vue";
import ViewReleaseNotesModal from "./views/Products/components/ViewReleaseNotesModal.vue";
import DeleteScoreLevelModal from "./views/Products/components/DeleteScoreLevelModal.vue";

import { mapState, mapActions } from "vuex";
import Toast from "./components/Toast/Toast.vue";

export default {
  components: {
    HeaderWrapper,
    Content,
    QuitSurveyModal,
    FinishTimeModal,
    Toast,
    Loader,
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
  },
  computed: {
    ...mapState({
      modalComponent: (state) => state.modals.modalComponent,
      modalVisible: (state) => state.modals.modalVisible,
      modalProps: (state) => state.modals.props,
    }),
    modal() {
      switch (this.modalComponent) {
        case "finishTime":
          return FinishTimeModal;
        case "quitAssessment":
          return QuitSurveyModal;
        case "leaveParticipants":
          return LeaveParticipantsModal;
        case "removeParticipant":
          return RemoveParticipantModal;
        case "addingParticipants":
          return AddingParticipantsModal;
        case "requestDelete":
          return RequestDeleteModal;
        case "usersPreview":
          return UsersPreview;
        case "createGroup":
          return CreateGroup;
        case "jobRoleModal":
          return JobRoleModal;
        case "individualUserModal":
          return IndividualUserModal;
        case "manageUserModal":
          return ManageUserModal;
        case "createTeamModal":
          return CreateTeamModal;
        case "createCompanyModal":
          return CreateCompanyModal;
        case "editAdminModal":
          return EditAdminModal;
        case "deleteTeamModal":
          return DeleteTeamModal;
        case "addInvitesModal":
          return AddInvitesModal;
        case "removeInvitesModal":
          return RemoveInvitesModal;
        case "toggleUnlimitedInvitesModal":
          return ToggleUnlimitedInvitesModal;
        case "removeMemberModal":
          return RemoveMemberModal;
        case "addSellersModal":
          return AddSellersModal;
        case "exitAssessmentModal":
          return ExitAssessmentModal;
        case "deactivateAccountModal":
          return DeactivateAccountModal;
        case "deactivateJobRoleModal":
          return DeactivateJobRoleModal;
        case "editJobRoleOrCompetencyModal":
          return EditJobRoleOrCompetencyModal;
        case "deactivateCustomerUserModal":
          return DeactivateCustomerUserModal;
        case "removeAdminRightsModal":
          return RemoveAdminRightsModal;
        case "deactivateCompanyModal":
          return DeactivateCompanyModal;
        case "dataDeletionModal":
          return DataDeletionModal;
        case "confirmDataDeleteModal":
          return ConfirmDataDeleteModal;
        case "confirmSubscriptionUpdateModal":
          return ConfirmSubscriptionUpdateModal;
        case "assessmentDateModal":
          return AssessmentDateModal;
        case "deleteProductModal":
          return DeleteProductModal;
        case "deleteDraftAssessmentModal":
          return DeleteDraftAssessmentModal;
        case "deleteCompetencyModal":
          return DeleteCompetencyModal;
        case "copyCompetenciesModal":
          return CopyCompetenciesModal;
        case "copyJobRolesModal":
          return CopyJobRolesModal;
        case "deleteJobRoleModal":
          return DeleteJobRoleModal;
        case "deleteSurveyModal":
          return DeleteSurveyModal;
        case "completeSetupModal":
          return CompleteSetupModal;
        case "publishProductModal":
          return PublishProductModal;
        case "manageAssessmentModal":
          return ManageAssessmentModal;
        case "retireAssessmentModal":
          return RetireAssessmentModal;
        case "newVersionSettingsModal":
          return NewVersionSettingsModal;
        case "scheduleProductLaunchModal":
          return ScheduleProductLaunchModal;
        case "viewReleaseNotesModal": 
          return ViewReleaseNotesModal;
        case "deleteScoreModal":
          return DeleteScoreLevelModal;
        default:
          return null;
      }
    },
  },
  mounted() {
    let initialDate = +new Date();
    window.onclick = () => (initialDate = +new Date());
    window.onfocus = () => (initialDate = +new Date());
    window.onscroll = () => (initialDate = +new Date());
    const freq = setInterval(async () => {
      const passedTime = Math.floor((+new Date() - initialDate) / 1000);
      if (passedTime > 3600) {
        clearInterval(freq);
        await this.logout({ doRedirect: true });
        window.location.reload();
      }
    }, 1000);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700&display=swap");
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  *:focus {
    outline: none;
  }
}
</style>
