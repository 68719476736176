<template>
  <Modal :data="data" :isVisible="true">
    <div class="flex flex-row ">
      <div class="flex flex-row flex-grow justify-end">
        <Button type="tertiary" size="medium" text="Cancel" @click.native="closeModal" class="mr-2" />
        <Button :type="buttonType" :text="confirmText" size="medium" @click.native="handleUpdate" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ConfirmSubscriptionUpdateModal',
  props: [ 'acceptFn', 'title', 'content', 'confirmText', 'buttonType'],
  computed: {
    data() {
      return {
        title: this.title,
        content:  this.content,
      }
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'modals/closeModal',
    }),
    async handleUpdate() {
      await this.acceptFn();
      this.closeModal();
    },
  },
};
</script>
