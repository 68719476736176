<template>
  <nav class="shadow-nav bg-white">
    <div
      class="flex items-center flex-wrap py-2 ml-8 mr-4 max-h-header h-header"
      :class="isImpersonating ? '' : 'justify-between'"
    >
      <div class="flex items-center flex-shrink-0 mr-6">
        <router-link to="/">
          <img src="@/assets/img/logo.svg" width="130" />
        </router-link>
      </div>
      <div
        v-if="this.role !== 'employee' && this.role !== 'seller'"
        class="flex"
      >
        <p
          class="text-sm-2 py-1 px-3 bg-grey-light-3 text-grey-light font-bold cursor-pointer"
          v-for="(item, index) in menuData"
          :key="item.role"
          :class="[
            isToggleActive(item.role),
            index === 0 && 'rounded-l',
            index + 1 === menuData.length && 'rounded-r',
          ]"
          @click="changeView(item.role)"
        >
          {{ item.title }}
        </p>
      </div>
      <div
        v-if="this.role === 'seller' || isImpersonating"
        class="flex align-center weird-centering"
      >
        <p
          class="text-sm-2 py-1 px-3 bg-grey-light-3 text-grey-light font-bold cursor-pointer rounded-l"
          :class="highlightedPlatformButton ? 'bg-blue-light-5 text-blue-dark-1' : null"
          @click="toMyPlatform"
          data-testid="my-platform-button"
        >
          MY PLATFORM
        </p>
        <p
          class="text-sm-2 py-1 px-3 bg-grey-light-3 text-grey-light font-bold cursor-pointer rounded-r "
          :class="highlightedPlatformButton ? null : 'bg-blue-light-5 text-blue-dark-1'"
          @click="goHome"
          data-testid="customer-management-button"
        >
          CUSTOMER MANAGEMENT
        </p>
      </div>
      <div class="flex items-center flex-shrink-0">
        <!-- <div
          class="p-1 mr-1 rounded-full hover:bg-grey-light-1 max-w-32 max-h-32 flex flex-col justify-center"
          @click="switchRole"
        >
          <img
            src="@/assets/img/header/notifications.svg"
            width="16"
            height="20"
          />
        </div> -->
        <div
          class="p-1 z-10 rounded-full hover:bg-grey-light-1 max-w-32 max-h-32 flex flex-col justify-center cursor-pointer relative"
          v-click-outside
          @click="toggleDropdown"
          v-if="!isImpersonating"
          data-testid="account-button"
        >
          <img
            src="@/assets/img/header/account.svg"
            width="22"
            class="active:outline-none"
          />
          <div
            class="absolute w-250 bg-white rounded border border-blue-dark-1 top-4 right-2"
            :class="visibleDropdown ? 'block' : 'hidden'"
          >
            <div class="px-2 py-1 justify-center items-center">
              <p class="text-sm text-grey-medium-2">Logged in as</p>
              <p class="font-bold text-md text-grey-medium-2">
                {{ firstName }} {{ lastName }}
              </p>
            </div>
            <button
              class="w-full parent px-2 py-1 focus:outline-none"
              @click="goToAccount"
              data-testid="go-to-account-button"
            >
              <p
                class="text-left flex-grow font-medium text-md text-grey-dark-1 child"
              >
                My Account
              </p>
            </button>
            <button
              class="w-full parent px-2 py-1 focus:outline-none"
              @click="doLogout"
              data-testid="logout-button"
            >
              <p
                class="text-left flex-grow font-medium text-md text-grey-dark-1 child"
              >
                Log Out
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Header",
  data: () => ({ visibleDropdown: false }),
  computed: {
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
    }),
    ...mapState({
      platformVsHome: (state) => state.home.platformVsHome,
      roles: (state) => state.home.roles,
      role: (state) => state.home.role,
      renderAs: (state) => state.home.renderAs,
      firstName: (state) => state.home.firstName,
      lastName: (state) => state.home.lastName,
    }),
    menuData() {
      return (
        this.roles.length > 1 &&
        this.roles
          .map((role) => ({
            role: role,
            title: this.getTitle(role),
          }))
          .reverse()
      );
    },
    highlightedPlatformButton() {
      const currentPath = this.$route.path;
      const myPlatformRoutes = [
        '/my-platform', '/job-roles', '/competencies', '/products', '/invites', '/open-assessments'
      ]
      return myPlatformRoutes.some(route => currentPath.startsWith(route));
    }
  },
  methods: {
    ...mapActions({
      setPlatformVsHome: "home/setPlatformVsHome",
      switchToAdmin: "home/switchToAdmin",
      switchToSeller: "home/switchToSeller",
      switchToEmployee: "home/switchToEmployee",
      changeRenderView: "home/changeRenderView",
      cleanImpersonation: "auth/cleanImpersonation",
      logout: "auth/logout",
    }),
    getTitle(role) {
      switch (role) {
        case "employee":
          return "MY ASSESSMENTS";
        case "admin":
          return "MY COMPANY";
        case "manager":
          return "MY TEAM";
        case "seller":
          return "CUSTOMER MANAGEMENT";
      }
    },
    toggleDropdown() {
      this.visibleDropdown = !this.visibleDropdown;
    },
    switchRole() {
      if (this.$route.fullPath !== "/") {
        this.$router.push("/");
      }
      if (this.role === "admin") {
        return this.switchToEmployee();
      }
      if (this.role === "seller") {
        return this.switchToSeller();
      }
      return this.switchToAdmin();
    },
    async doLogout() {
      this.closeDropdown();
      await this.logout({ doRedirect: true });
    },
    goHome() {
      this.setPlatformVsHome({ payload: false });
      if (this.isImpersonating) {
        this.cleanImpersonation({ toCompany: false });
      } else {
        this.$router.push("/");
      }
    },
    toMyPlatform() {
      this.setPlatformVsHome({ payload: true });
      if (this.isImpersonating) {
        this.cleanImpersonation({ otherPath: "/my-platform" });
      } else {
        this.$router.push("/my-platform");
      }
    },
    changeView(role) {
      if (this.renderAs === role) return;
      this.changeRenderView({ role });
      if (this.$route.fullPath !== "/") {
        this.$router.push("/");
      }
    },
    isToggleActive(role) {
      return this.renderAs === role ? "bg-blue-light-5 text-blue-dark-1" : "";
    },
    goToAccount() {
      this.$router.push("/my-account");
      this.closeDropdown();
    },
    closeDropdown() {
      this.visibleDropdown ? (this.visibleDropdown = false) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.parent:hover {
  background-color: #e0eef9;
  .child {
    color: #00a1d5;
  }
}
.weird-centering {
  position: absolute;
  left: 50%;
  right: 50%;
  width: 360px;
  transform: translate(-50%, 0%);
}
</style>
